import { DOCUMENT } from '@angular/common';
import { InjectionToken, Provider, inject, isDevMode } from '@angular/core';
import { EnvironmentID } from '@plutus-realty/models/environment';
import { ClientConfig, OAuthClientConfig, OAuthServerConfig } from '@plutus-realty/web-config/client';
import type { Config } from 'tailwindcss';
import { isBrowser } from './utilities/platform-type';

export const ENVIRONMENT_ID = new InjectionToken<EnvironmentID>('ENVIRONMENT_ID');

export function provideBrowserEnvID(): Provider {
  return {
    provide: ENVIRONMENT_ID,
    useFactory: () => {
      const parent = inject(ENVIRONMENT_ID, { optional: true, skipSelf: true });
      if (parent)
        return parent;

      if (isBrowser()) {
        const hostname = window.location.hostname;
        switch (true) {
          case hostname.endsWith('localhost'): return 'local';
          case hostname.endsWith('__development.plutusrealty.com'): return 'development';
          case hostname.endsWith('plutusrealty.com'): return 'production';
          default: throw new Error('unknown environment');
        }
      }

      // the vite dev-server is unable to use 
      // the injected ENVIRONMENT_ID from fastify
      // so we provide 'local' by default
      return 'local';
    }
  };
}

type Theme = Config['theme'];

declare const __TW_THEME: Theme | undefined;


export const SW_URL = new InjectionToken<string>('SW_URL: The service worker url');
export const CLIENT_CONFIG = new InjectionToken<ClientConfig>('CLIENT_CONFIG: The client config object');

export const IS_AUTH_DOMAIN = new InjectionToken<boolean>('Whether the current site is the auth origin', {
  providedIn: 'root',
  factory: () => {
    const clientConfig = inject(CLIENT_CONFIG);
    return clientConfig.oauth.origin === window.location.origin;
  }
});

export const AUTH_GUARD_SIGN_IN_ACTION = new InjectionToken<() => void>('', {
  providedIn: 'root',
  factory: () => {
    return () => console.error('auth guard sign in action not implemented');
  }
});

export const OAUTH_SERVER_CONFIG = new InjectionToken<OAuthServerConfig>('OAUTH_SERVER_CONFIG', {
  providedIn: 'root',
  factory: () => {
    const isAuthDomain = inject(IS_AUTH_DOMAIN);
    if (!isAuthDomain)
      throw new Error('cannot use oauth host config in client domain');

    const config = inject(CLIENT_CONFIG).oauth;

    if (config.type !== 'server')
      throw new Error('invalid oauth host config was provided as part of ClientConfig');

    return config;
  }
});

export const OAUTH_CLIENT_CONFIG = new InjectionToken<OAuthClientConfig>('OAUTH_CLIENT_CONFIG', {
  providedIn: 'root',
  factory: () => {
    const isAuthDomain = inject(IS_AUTH_DOMAIN);
    if (isAuthDomain)
      throw Error('cannot use oauth client config in auth domain');

    const config = inject(CLIENT_CONFIG).oauth;
    if (config.type !== 'client')
      throw new Error('invalid oauth client config was provided as part of ClientConfig');

    return config;
  }
});

export const TW_THEME = new InjectionToken<NonNullable<Theme>>('TW_THEME', {
  providedIn: 'root',
  factory: () => {
    if (!__TW_THEME)
      throw new Error('cannot inject tailwind theme - __TW_THEME is unavailable');
    if (isDevMode())
      console.debug(__TW_THEME);
    return __TW_THEME;
  }
});

